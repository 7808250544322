import {
  MetaContentFragment,
  MetaContentHead,
  MetaContentProps,
} from '@/components/common/MetaContentHead'
import { Layout } from '@/components/Layout'
import PageBlockRenderer from '@/components/pageBlocks/PageBlockRenderer'
import { fetchMenuCategories, fetchPageComponents } from '@/utils/Contentful'
import { fetchContent } from '@/utils/FetchContent'
import React from 'react'
import {
  TemplateJoinUsCollection,
  TemplateJoinUsPageComponentsCollection,
} from 'types/generated/contentful-types'
import { GetStaticProps } from 'next'
import minify from 'gqlmin'

type JoinUsProps = {
  page: {
    title: string
    metaContent: MetaContentProps
    showHeader: boolean
    collapseHeader: boolean
    hideGlobalBanner: boolean
  }
  components: TemplateJoinUsPageComponentsCollection
  isPreview: boolean
  menuLinks: any[]
}

const JoinUs = ({
  page,
  components,
  isPreview,
  menuLinks,
}: JoinUsProps): JSX.Element => {
  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: 'Your dream job, in travel.',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/join-us`,
  }

  return (
    <>
      <Layout
        showHeader={page.showHeader}
        showPreviewBanner={isPreview}
        collapseHeaderMargin={page.collapseHeader}
        hideGlobalBanner={page.hideGlobalBanner}
        menuLinks={menuLinks}
      >
        <MetaContentHead {...page.metaContent} jsonLd={jsonLd} />
        <PageBlockRenderer {...components} />
      </Layout>
    </>
  )
}

export const getStaticProps: GetStaticProps = async ({ preview = false }) => {
  const response = await fetchContent<{
    templateJoinUsCollection: TemplateJoinUsCollection
  }>(
    minify(`
    {
      templateJoinUsCollection(limit: 1, preview: ${preview}) {
        items {
          title
          ${MetaContentFragment}
          showHeader
          collapseHeader
          hideGlobalBanner
        }
      }
    }
  `),
    preview
  )

  return {
    props: {
      page: response?.templateJoinUsCollection?.items?.[0] || null,
      components: await fetchPageComponents(
        'templateJoinUsCollection',
        preview
      ),
      isPreview: preview,
      menuLinks: await fetchMenuCategories(),
    },
  }
}

export default JoinUs
